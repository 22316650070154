// images
import blogImg1 from '../images/blog/web-design.webp';
import blogImg2 from '../images/blog/google_ads.jpg';
import blogImg3 from '../images/blog/seo.webp';
import blogImg4 from '../images/blog/revamp.webp';
import blogImg5 from '../images/blog/landing-page.webp';
import blogImg6 from '../images/blog/web-access.webp';
import blogImg7 from '../images/blog/pwa.webp';
import blogImg8 from '../images/blog/AI.webp';
import blogImg9 from '../images/blog/web-company.png';
import blogImg10 from '../images/blog/redesign.webp';
import blogImg11 from '../images/blog/ui-design.png';
import blogImg12 from '../images/blog/designMistake.jpg';
import blogImg13 from '../images/blog/blog-single/typography.webp';
import blogImg14 from '../images/blog/blog-single/webCost.webp';

import blogSingleImg1 from '../images/blog/blog-single/web-design.webp';
import blogSingleImg2 from '../images/blog/blog-single/google_ads.jpg';
import blogSingleImg3 from '../images/blog/blog-single/seo.webp';
import blogSingleImg4 from '../images/blog/blog-single/revamp.webp';
import blogSingleImg5 from '../images/blog/blog-single/landing-page.webp';
import blogSingleImg6 from '../images/blog/blog-single/web-access.webp';
import blogSingleImg7 from '../images/blog/blog-single/pwa.webp';
import blogSingleImg8 from '../images/blog/blog-single/AI.webp';
import blogSingleImg9 from '../images/blog/blog-single/web-company.png';
import blogSingleImg10 from '../images/blog/blog-single/redesign.webp';
import blogSingleImg11 from '../images/blog/blog-single/ui-design.png';
import blogSingleImg12 from '../images/blog/blog-single/designMistake.jpg';
import blogSingleImg13 from '../images/blog/blog-single/typography.webp';
import blogSingleImg14 from '../images/blog/blog-single/webCost.webp';


import SearchEngineOptimization from '../components/blogs/Search-Engine-Optimization';
import WebDesign from '../components/blogs/WebDesign';
import GoogleVsFacebook from '../components/blogs/Google-Vs-Facebook';
import RevampWebDesign from '../components/blogs/RevampWebDesign';
import LandingPageWebDesign from '../components/blogs/LandingPageWebDesign';
import WebAccessibility from '../components/blogs/WebAccessibility';
import ProgressiveWebApp from '../components/blogs/ProgressiveWebApp';
import WebDevelopmentFuture from '../components/blogs/WebDevelopmentFuture';
import UnlockOnlinePresence from '../components/blogs/UnlockOnlinePresence';
import Redesign from '../components/blogs/Redesign';
import UXDesign from '../components/blogs/UXDesign';
import DesignMistake from '../components/blogs/DesignMistake';
import Typography from '../components/blogs/Typography';
import WebCost from '../components/blogs/WebCost';

const blogs = [
    {
        id: '1',
        slug: 'web-design-tips-from-web-design-company-in-jaffna-to-improve-sales',
        title: 'Web Design Tips from a Web Design Company in Jaffna',
        dicsrip: ' Elevate your online sales with these five essential web design tips tailored for success. In the competitive digital landscape, understanding the significance of a well-crafted website is crucial. This introduction delves into key strategies to enhance sales through your website, emphasizing the expertise of a leading web design company in Jaffna.',
        metaDescription: 'Discover essential web design tips from a top web design company in Jaffna to improve sales through your website.',
        screens: blogImg1,
        body: <WebDesign />,
        author: 'Web Design',
        comment: '24',
        authorTitle: 'Admin',
        blClass: 'format-video',
        create_at: '14 Oct 2022',
        blogSingleImg: blogSingleImg1,
        category: 'web-design',
        order : 1
    },
    {
        id: '2',
        slug: 'seo-company-in-sri-lanka-google-ads-vs-facebook-ads-which-one-to-choose',
        title: 'How an SEO Company in Sri Lanka Helps You Choose Between Google Ads and Facebook Ads',
        dicsrip: "In the digital age, online advertising is crucial for businesses aiming to increase their reach and visibility. However, choosing the right platform for ads can be challenging. Google Ads and Facebook Ads are two of the most popular options. Therefore, understanding which is more effective is essential. ",
        metaDescription: "Discover how an SEO company in Sri Lanka can help you choose between Google Ads and Facebook Ads, enhancing your online advertising success.",
        screens: blogImg2,
        body: <GoogleVsFacebook />,
        author: 'Advertisement',
        authorTitle: 'Admin',
        comment: '75',
        blClass: 'format-video',
        create_at: '15 Sep 2022',
        blogSingleImg: blogSingleImg2,
        category:'web-design',
        order : 2
    },
    {
        id: '3',
        slug: 'how-to-choose-the-right-seo-company-in-sri-lanka',
        title: 'How to Choose the Right SEO Company in Sri Lanka for Your Business',
        dicsrip:
            'Selecting the right SEO company in Sri Lanka is essential for boosting your online presence. This guide helps businesses understand their SEO needs, research potential companies, and evaluate strategies, communication, and pricing. With the right partner, you can achieve higher rankings, increased traffic, and long-term growth. ',
            metaDescription: "Find the best SEO company in Sri Lanka to boost your website rankings, drive traffic, and grow your business",
        screens: blogImg3,
        body: <SearchEngineOptimization />,
        author: 'SEO',
        authorTitle: 'Admin',
        create_at: '02 Nov 2022',
        blogSingleImg: blogSingleImg3,
        comment: '95',
        blClass: 'format-video',
        category:'seo',
        order : 3
    },

    {
        id: '4',
        slug: 'revamping-website-web-design-company-sri-lanka',
        title: 'Revamping Your Website with a Web Design Company in Sri Lanka',
        dicsrip:
            'Explore essential insights for a successful website revamp with expert tips from a premier web design company in Sri Lanka. Elevate your online presence and user experience with industry-leading guidance. ',
        metaDescription: 'Discover essential strategies and expert tips for a successful website revamp with a leading web design company in Sri Lanka.',
        screens: blogImg4,
        body: <RevampWebDesign />,
        author: 'Web Design',
        authorTitle: 'Admin',
        create_at: '20 Dec 2023',
        blogSingleImg: blogSingleImg4,
        comment: '95',
        blClass: 'format-video',
        category:'web-design',
        order : 4
    },

    {
        id: '5',
        slug: 'effective-landing-page-guidelines-web-design-jaffna',
        title: 'Effective Landing Page Guidelines for Web Design in Jaffna',
        dicsrip: 'Discover essential landing page guidelines for successful web design in Jaffna. Elevate user experience, enhance online presence, and boost conversions with expert insights. Explore key strategies, design principles, and optimization tips to create compelling landing pages tailored for the Jaffna audience. Transform your web design approach for impactful results',
        screens: blogImg5,
        body: <LandingPageWebDesign />,
        metaDescription: 'Discover key strategies and expert tips for crafting effective landing pages with top web design services in Jaffna.',
        author: 'Web Design',
        authorTitle: 'Admin',
        create_at: '20 Dec 2023',
        blogSingleImg: blogSingleImg5,
        comment: '95',
        blClass: 'format-video',
        category:'web-design',
        order : 5
    },
    

    {
        id: '6',
        slug: 'importance-of-web-accessibility-in-web-design',
        title: 'The Importance of Web Accessibility in Web design',
        dicsrip:
            'Discover why web accessibility is vital in today\'s design in Jaffna. This helpful guide covers crucial aspects, from being inclusive and following laws to improving user experience and building a positive brand image. Learn why using accessible practices is essential for creating websites that meet diverse needs and promote a universally inclusive digital environment.',
        metaDescription: 'Web accessibility in Jaffna web design ensures inclusivity, legal compliance, enhanced SEO, and a positive brand image for all users.',
        screens: blogImg6,
        body: <WebAccessibility />,
        author: 'Web Design',
        authorTitle: 'Admin',
        create_at: '20 Dec 2023',
        blogSingleImg: blogSingleImg6,
        comment: '95',
        blClass: 'format-video',
        category:'web-design',
        order : 6
    },

    {
        id: '7',
        slug: 'introduction-to-progressive-web-apps-in-web-development-jaffna',
        title: 'Introduction to Progressive Web Apps in Web Development in Jaffna',
        dicsrip:
            'Discover the world of Progressive Web Apps (PWAs) in Jaffna web development. Uncover features like offline functionality and app-like experiences as PWAs bridge traditional websites and native mobile apps.',
        metaDescription:'Explore Progressive Web Apps (PWAs) in Jaffna web development and learn how they offer offline functionality and app-like experiences.',
        screens: blogImg7,
        body: <ProgressiveWebApp />,
        author: 'Web Development',
        authorTitle: 'Admin',
        create_at: '20 Dec 2023',
        blogSingleImg: blogSingleImg7,
        comment: '95',
        blClass: 'format-video',
        category: 'web-development',
        order : 7
    },
    
    {
        id: '8',
        slug: 'navigating-web-development-trends-innovations-in-sri-lanka',
        title: 'Navigating Trends and Innovations in Web Development',
        dicsrip:
            'Discover the latest in web development with "Navigating Trends and Innovations in Sri Lanka." This guide explores emerging trends like Progressive Web Apps and AI integration, providing a comprehensive overview of innovations shaping the field in Sri Lanka. Stay ahead in the dynamic world of web development.',
        metaDescription:
            'Explore cutting-edge trends and innovations in Sri Lankan web development, including Progressive Web Apps and AI integration. Stay informed and ahead with our comprehensive guide.',
        screens: blogImg8,
        body: <WebDevelopmentFuture />,
        author: 'Web Development',
        authorTitle: 'Admin',
        create_at: '21 Dec 2023',
        blogSingleImg: blogSingleImg8,
        comment: '95',
        blClass: 'format-video',
        category: 'web-development',
        order : 8
    },
    

    {
        id: '9',
        slug: 'unlock-online-presence-web-design-companies-sri-lanka',
        title: 'Unlock Online Presence with Web Design Companies in Sri Lanka',
        dicsrip:
            'Embark on a digital transformation with premier web design companies in Sri Lanka! Unleash your brand\'s online magic with creative designs and tailored solutions. Elevate your business identity through beautiful websites, ensuring a seamless user experience and watch your brand soar to new heights!',
        metaDescription: 'Transform your brand’s online presence with top web design companies in Sri Lanka. Discover creative solutions to elevate your identity.',
        screens: blogImg9,
        body: <UnlockOnlinePresence />,
        description: <UnlockOnlinePresence />,
        describe: ' ',
        author: 'Web Design',
        authorTitle: 'Admin',
        create_at: '22 Dec 2023',
        blogSingleImg: blogSingleImg9,
        comment: '95',
        blClass: 'format-video',
        category:'web-design',
        order : 9
    },

    {
        id: '10',
        slug: 'essential-factors-to-consider-when-redesigning-website',
        title: 'Essential Factors to Consider When Redesigning Your Website',
        dicsrip:
            'Redesigning your website is a crucial step to ensure it stays relevant, functional, and user-friendly. Whether you are focusing on enhancing user experience, updating your brand’s image, or improving your site’s performance, a well-thought-out redesign is essential. Here are some important factors to consider when planning your website redesign, especially if you are focusing on web design in Sri Lanka.',
        metaDescription: 'Discover the essential factors for a successful website redesign, focusing on user experience, SEO, and content strategy.',
        screens: blogImg10,
        body: <Redesign />,
        description: <Redesign />,
        describe: ' ',
        author: 'Web Design',
        authorTitle: 'Admin',
        create_at: '15 Jan 2024',
        blogSingleImg: blogSingleImg10,
        comment: '95',
        blClass: 'format-video',
        category:'web-design',
        order : 10
    },

    {
        id: '11',
        slug: 'how-to-optimize-your-website’s-UX-for-better-conversions',
        title: 'How to Optimize Your Website’s UX for Better Conversions',
        dicsrip:
            'Optimizing your website’s user experience (UX) is crucial for increasing conversions and ensuring your visitors have a positive interaction with your site. Whether you\'re updating an existing site or creating a new one, these strategies will help you achieve better results, particularly if you focus on web design in Jaffna.',
        metaDescription: 'Optimize your website\'s UX for better conversions. Explore effective web design strategies in Jaffna for new or existing sites.',
        screens: blogImg11,
        body: <UXDesign />,
        description: <UXDesign />,
        describe: ' ',
        author: 'Web Design',
        authorTitle: 'Admin',
        create_at: '10 Feb 2024',
        blogSingleImg: blogSingleImg11,
        comment: '95',
        blClass: 'format-video',
        category:'web-design',
        order : 11
    },

    {
        id: '12',
        slug: 'Web-Design-Mistakes-to-Avoid',
        title: 'Web Design Mistakes to Avoid: A Guide for Small Businesses',
        dicsrip:
            'Effective web design is crucial for small businesses as it directly impacts user experience, brand perception, and conversion rates. A well-designed website can establish credibility, engage visitors, and drive sales, making it a valuable asset in a competitive market. For those operating in niche markets, like web design in Jaffna, the stakes are even higher as local competition can be intense.',
        metaDescription: 'Avoid common web design mistakes with our guide for small businesses. Enhance user experience, brand perception, and conversion rates',
        screens: blogImg12,
        body: <DesignMistake />,
        description: <DesignMistake />,
        author: 'Web Design',
        authorTitle: 'Admin',
        create_at: '05 MAR 2024',
        blogSingleImg: blogSingleImg12,
        comment: '95',
        blClass: 'format-video',
        category:'web-design',
        order : 12
    },

    {
        id: '13',
        slug: 'Essential-Typography-Tips-for-Enhancing-Website-User-Experience',
        title: 'Essential Typography Tips for Enhancing Website User Experience',
        dicsrip:
            'Effective typography is crucial in web design in Sri Lanka. By choosing readable fonts, maintaining a clear hierarchy, optimizing line length and spacing, limiting font variety, and ensuring good contrast, you can enhance user experience. Good typography creates a more engaging, accessible, and user-friendly website for visitors.',
        metaDescription: 'Enhance user experience with effective typography in web design in Sri Lanka by using readable fonts, hierarchy, spacing, and contrast',
        screens: blogImg13,
        body: <Typography />,
        description: <Typography />,
        author: 'Web Design',
        authorTitle: 'Admin',
        create_at: '15 APR 2024',
        blogSingleImg: blogSingleImg13,
        comment: '95',
        blClass: 'format-video',
        category:'web-design',
        order : 13
    },

    {
        id: '14',
        slug: 'Key-Factors-Influencing-Website-Costs',
        title: 'Key Factors Influencing Website Costs',
        dicsrip:
            'When considering a new website, understanding the costs involved is essential, especially when working with a web development company in Sri Lanka. Several key factors will influence how much you’ll spend on your website. Let’s explore these factors in detail.',
        metaDescription: 'Discover key factors influencing website costs and see how a web development company in Sri Lanka can help you succeed online.',
        screens: blogImg14,
        body: <WebCost/>,
        description: <WebCost/>,
        author: 'Web Development',
        authorTitle: 'Admin',
        create_at: '20 MAY 2024',
        blogSingleImg: blogSingleImg14,
        comment: '95',
        blClass: 'format-video',
        category:'web-developement',
        order : 14
    },
];
export default blogs;
